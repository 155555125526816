import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class NaturezaOperacaoVgService {
    buscar(filtros) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/natureza-operacao`, {
                params: {
                    page: filtros.page,
                    pageSize: filtros.pageSize,
                    natureza: filtros.natureza  
                }              
            });
    }
    buscarPorNatureza(natureza) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/natureza-operacao/${ natureza }`);
    }
}

export default new NaturezaOperacaoVgService();