import axios from "axios";
import ApiUrl from '../config/ApiUrl';
class DestinoMercadoriaService {

    lista() {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/destino-mercadoria`);
    }

    buscarPorCodigo(codigo) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/destino-mercadoria/buscar-por/codigo/${ codigo }`);
    }
}

export default new DestinoMercadoriaService();